import styled from 'styled-components';

export const ChatRoomHeaderStyled = styled.div`
    .chatHeader {
        background: linear-gradient(90deg, #519ffa -40.84%, #8a5be8 210.18%);
        color: white;
        padding: 0.5rem 1rem;
        gap: 10px;
        justify-content: space-between;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        min-height: 60px;
        position: relative;
	    z-index: 9;
    }
    .closeChatButton {
        filter: invert(1) brightness(3) contrast(5);
    }
    .close-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
    }

    .close-icon:hover {
        transform: scale(1.2);
    }
`;
