import React, { useState } from "react";
import { motion } from "framer-motion";

interface BottomDrawerProps {
  children: React.ReactNode;
  onToggle?: (expanded: boolean) => void; // Make onToggle optional but ensure it's always a function when passed
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({ children, onToggle = () => { } }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleToggle = (expanded: boolean) => {
    setExpanded(expanded);
    onToggle(expanded); // Safely call onToggle
  };

  return (
    <motion.div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: isExpanded ? "90vh" : "50px",
        backgroundColor: "white",
        zIndex: 1000,
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
      animate={{ y: isExpanded ? 0 : "calc(100% - 50px)" }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      onDragEnd={(_, info) => {
        handleToggle(info.offset.y <= 100);
      }}
      transition={{ type: "spring", stiffness: 200, damping: 20 }}
    >
      <div
        style={{
          width: "40px",
          height: "4px",
          backgroundColor: "#ccc",
          margin: "14px auto",
          borderRadius: "2px",
          cursor: "pointer",
          position: "fixed",
          left: "48%",
          zIndex: 10,
        }}
        onClick={() => handleToggle(!isExpanded)}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    </motion.div>
  );
};

export default BottomDrawer;