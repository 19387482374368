import React, { useContext } from "react";
import { ChatButtonComponent } from "../chatbutton/ChatButton.component";
import BottomDrawer from "./BottomDrawer";
import { getQueryParam } from "../../utils/UrlUtils";
import { ChatRoomComponent } from "../chatroom";
import { ShowChatWindowContext } from "../../context/ShowChatWindowContext";

export const ChatbotWrapper: React.FC = () => {
    const gramMode = getQueryParam("gramMode");
    const { showChatWindow } = useContext(ShowChatWindowContext);

    if (gramMode === "edge") {
        // Render the iframe in the bottom drawer
        return (
            <BottomDrawer>
                {showChatWindow && (
                    <div id="ai-everywhere-client">
                        <ChatRoomComponent />
                    </div>
                )}
            </BottomDrawer>
        );
    }

    // Render the default button and chatbot
    return (
        <>
            {showChatWindow && (
                <div className="AIEverywhereClient">
                    <ChatRoomComponent />
                </div>
            )}
            <ChatButtonComponent open={showChatWindow} />
        </>
    );
};