import {FacadeServiceClient} from "./FacadeServiceClient";
import { CustomerInfo } from "src/model/CustomerInfo";
import { ApplicationDetail } from "./model/ApplicationDetail";
import { CustomerContactType } from '../../model/CustomerContactType';
import { Service } from '../../model/Service';
import { User } from "src/model/User";

export class PlaygroundFacadeServiceClient implements FacadeServiceClient {
    private static readonly conversationId: string = new Date().getTime().toString();

    constructor(private readonly realFacadeServiceClient: FacadeServiceClient) {
    }

    public async getCustomer(serviceId: string, customerId: string): Promise<CustomerInfo | undefined> {
        return {
            id: customerId,
            latestConversationId: PlaygroundFacadeServiceClient.conversationId,
            activities: new Map(),
            metadata: new Map(),
            customerContact: customerId,
            contactType: CustomerContactType.NONE
        }
    }

    public async upsertCustomer(serviceId: string, customer: CustomerInfo): Promise<void> {}

    public async createConversation(serviceId: string, applicationId: string, customerId: string): Promise<string> {
        return PlaygroundFacadeServiceClient.conversationId;
    }

    public getServiceByServiceId(serviceId: string): Promise<Service> {
        return this.realFacadeServiceClient.getServiceByServiceId(serviceId);
    }

    public getApplication(serviceId: string): Promise<ApplicationDetail> {
        return this.realFacadeServiceClient.getApplication(serviceId);
    }

    public getGuestToken(chatSessionId: string): Promise<string> {
        return this.realFacadeServiceClient.getGuestToken(chatSessionId);
    }

    public async getUsersByServiceId(serviceId: string): Promise<User[]> {
        return [];
    }

}