import styled from 'styled-components';

export const ChatRoomStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px); /* 100% of the viewport height minus offsets */
    border-radius: 16px;
    animation: fadeIn 0.5s ease-in-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .AIEveywhereChatInput {
        position: fixed;
        bottom: 0; /* parent's bottom */
        padding: 8px 24px 0px 24px;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
    }
    .AIEverywhereChatButton {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: flex-end;
    }
    .AIEverywhereCopyright {
        font-size: 0.8em;
        color: gray;
        margin: 0 0 4px;
    }

    .messageList {
        flex-grow: 1;
        overflow-y: auto; /* For scroll if content overflows */
    }

    .messageLine {
        display: block;
        font-weight: bold;
    }
    .messageLink {
        font-weight: normal;
    }
`;
