import React, { useContext, useEffect } from 'react';
import { ChatRoomComponent } from './components/chatroom';
import { ClientFactory } from './client/ClientFactory';
import { ShowChatWindowContext } from './context/ShowChatWindowContext';
import { ChatButtonComponent } from './components/chatbutton/ChatButton.component';
import { AppStyled } from './Styled';
import { IdentifiersContext } from './context/Identifiers.context';
import { ActionType, ShowChatWindowAction } from './model/ActionTypes';
import { ChatbotWrapper } from "./components/Drawer/ChatbotWrapper";

function App() {
    const {serviceId, isReady} = useContext(IdentifiersContext);
    const { showChatWindow } = useContext(ShowChatWindowContext);

    useEffect(() => {
        if (serviceId) {
            ClientFactory.createAnalyticsClient(serviceId).initAnalytics();
        }
    }, [serviceId]);

    useEffect(() => {
        if(!ClientFactory.isPlaygroundMode()) {
            ClientFactory.createActionMessageClient().sendActionMessageToAgent({
                actionType: ActionType.ShowChatWindow,
                payload: showChatWindow
            } as ShowChatWindowAction);
        }
    }, [showChatWindow]);


    if (!isReady) {
        return <></>;
    } else {
        return (
            <AppStyled>
                {/* {showChatWindow && (
                    <div className="AIEverywhereClient">
                        <ChatRoomComponent />
                    </div>
                )}
                <ChatButtonComponent open={showChatWindow} /> */}
                <ChatbotWrapper />
            </AppStyled>
        );
    }
}

export default App;
