import { useContext, JSX } from 'react';
import { Button } from 'react-bootstrap';
import { ShowChatWindowContext } from '../../context/ShowChatWindowContext';
import { ClientFactory } from '../../client/ClientFactory';
import { ChatButtonStyled } from './Styled';
// @ts-ignore
import ChatBtnIcon from '../../assets/chat-btn.svg';
// @ts-ignore
import CloseBtnIcon from '../../assets/close-btn.svg';
import React from 'react';
import { IdentifiersContext } from '../../context/Identifiers.context';
import { MessageBubbleActionComponent } from './MessageBubbleAction.component';
interface Props {
    open: boolean;
}

export const ChatButtonComponent = ({ open }: Props): JSX.Element => {
    const { toggleChatWindow } = useContext(ShowChatWindowContext);
    const { serviceId, applicationId, isReady } = useContext(IdentifiersContext);

    const openChatWindow = () => {
        toggleChatWindow();

        if (isReady) {
            const analyticsClient = ClientFactory.createAnalyticsClient(serviceId);
            analyticsClient.sendCustomerOpenedChatBot(window.location.pathname, open);
        }
    };

    return (
        <ChatButtonStyled $isOpen={open}>
            <MessageBubbleActionComponent />
            <Button className="chatbotButton" onClick={() => openChatWindow()} variant="primary" style={{ borderRadius: '50%', border: 'none' }}>
                {open ? <img src={CloseBtnIcon} alt="close-button-icon" /> : <img src={ChatBtnIcon} alt="chat-button-icon" />}
            </Button>
        </ChatButtonStyled>
    );
};
